const w = window.innerWidth;

const styles = theme => ({
  root: {
    ...theme.contianer,
    ...theme.paddingVertical
  },
  menuText: {
    textTransform: "capitalize",
    width: w,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.transparent,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  menuContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 10,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  list: {
    width: 200,
    [theme.breakpoints.up("md")]: {
      width: 250
    },
    textTransform: "capitalize",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },

  content: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  image: {
    height: "100%",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      height: "auto",
      width: "100%"
    }
  },
  imageContainer: {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: w,
    width: w,
    [theme.breakpoints.up("md")]: {
      height: (w / 36) * 16,
      width: (w / 36) * 9
    }
  },
  drawer: {
    minHeight: "90%"
  }
});

export default styles;
