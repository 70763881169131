import React, { Component } from "react";

import PropTypes from "prop-types";
import styles from "./styles";

import { withStyles } from "@material-ui/core/styles";

import MenuBar from "./../../components/MenuBar";
import Banner from "./../../components/Banner";
import Contact from "./../../components/Contact";
import Products from "./../../components/Products";
import About from "./../../components/About";
import SocialMedia from "./../../components/SocialMedia";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "tr",
      storeIndex: 0,
      greeted: false,
    };
  }

  getStores = (language) => [
    {
      name: language === "tr" ? "Küçük Çamlıca (merkez)" : "primary",
      address: `Küçük Çamlıca Mahallesi Üçpınarlar Caddesi, Filiz Sk. No:1, 34696 Üsküdar / İstanbul, 
      ${language === "tr" ? "Türkiye" : "Turkey"}`,
      phone: "+90 216 474 46 64",
      mobile: "+905368715309",
      email: "info@kaynakkardesler.com",
      iframe:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1279.569429433772!2d29.07062526972824!3d41.00594502846473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac9bb60237c2b%3A0xfc42350228d7ee44!2zS2F5bmFrIEthcmRlxZ9sZXIgT3RvbWF0aWsgxZ5hbnrEsW1hbiB2ZSBQYXLDp2FsYXLEsSBMdGQu!5e0!3m2!1sen!2str!4v1578476095533!5m2!1sen!2str",
    },
  ];

  setLanguage = (language) => {
    this.setState({ language, greeted: language === this.state.language });
  };

  setGreeted = (greeted) => {
    this.setState({ greeted });
  };

  setStore = (storeIndex) => {
    this.setState({ storeIndex });
  };

  render() {
    const { classes } = this.props;
    const { language, storeIndex, greeted } = this.state;
    const stores = this.getStores(language);
    const store = stores[storeIndex];
    const media = <SocialMedia phone={store.phone} mobile={store.mobile} />;
    return (
      <div className={classes.root}>
        <MenuBar
          language={language}
          setLanguage={this.setLanguage}
          mediaComponent={media}
        ></MenuBar>
        <section id="home">
          <Banner
            greeted={greeted}
            setGreeted={this.setGreeted}
            language={language}
          />
        </section>

        <section id="products">
          <Products language={language} mediaComponent={media} />
        </section>
        <section id="about">
          <About language={language} />
        </section>
        <section id="contact">
          <Contact
            language={language}
            setStore={this.setStore}
            stores={stores}
            storeIndex={storeIndex}
            mediaComponent={media}
          />
        </section>

        <footer className={classes.footer}>
          <p>&copy;2020 by Kaynak Kardeşler.</p>
        </footer>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
