const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    ...theme.paddingVertical,
    paddingBottom: 0
  },
  button: {
    margin: theme.spacing(1)
  },
  boxContainer: {
    ...theme.container,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  box: {
    padding: 25,
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%"
    }
  },
  text: {
    marginBottom: theme.spacing(1) * 1.5
  },
  map: {
    height: 500
  }
});

export default styles;
