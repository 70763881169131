import background from "./../../assets/images/banner.png";
const height = window.innerHeight;
const width = window.innerWidth;

const styles = (theme) => ({
  root: {
    position: "relative",
  },
  banner: {
    height: width * 0.55 + 50,
    backgroundSize: "contain",
    backgroundPosition: "center 50px",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    [theme.breakpoints.up("md")]: {
      height: height,
      backgroundSize: "cover",
    },
  },
  logo: {
    position: "absolute",
    top: 70,
    left: width / 20,
    color: "#fff",
    textTransform: "capitalize",
  },
  logoImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: width / 5,
    height: width / 15,
  },
  logoText: {
    fontWeight: "bold",
    fontSize: theme.typography.fontSize * 0.8,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.fontSize * 0.6,
    },
  },
  overlay: {
    ...theme.paddingVertical,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: 0,
      left: 0,
      color: "#fff",
      backgroundColor: theme.palette.primary.transparent,
    },
    width: "100%",
  },
  content: {
    ...theme.container,
    position: "relative",
    maxWidth: 800,
    textTransform: "capitalize",
  },
  greeting: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cinzel: {
    fontFamily: "Cinzel-Bold",
  },
});

export default styles;
