import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  CardMedia,
  Drawer,
  Divider,
  Grid,
} from "@material-ui/core";

import { Link } from "react-scroll";

import MenuIcon from "@material-ui/icons/Menu";
import RoomIcon from "@material-ui/icons/Room";
import HomeIcon from "@material-ui/icons/Home";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import InfoIcon from "@material-ui/icons/Info";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";

import trIcon from "./../../assets/icons/tr.png";
import ukIcon from "./../../assets/icons/uk.png";

const drawerIcons = {
  home: <HomeIcon />,
  products: <ShoppingCartRoundedIcon />,
  about: <InfoIcon />,
  contact: <ContactPhoneIcon />,
};

const content = {
  tr: {
    home: "anasayfa",
    products: "ürünler",
    about: "hakkımızda",
    contact: "İletişim",
  },
  en: {
    home: "home",
    products: "products",
    about: "about",
    contact: "contact",
  },
};

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, language, setLanguage, mediaComponent } = this.props;
    const list = Object.keys(content[language]);
    return (
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item className={classes.menu}>
              <IconButton onClick={this.toggleDrawer}>
                <MenuIcon className={classes.icon} />
              </IconButton>
              <Drawer
                open={this.state.open}
                onClose={this.toggleDrawer}
                anchor="left"
              >
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  direction="column"
                  className={classes.drawer}
                >
                  <Grid item>
                    <List>
                      {list.map((name, i) => (
                        <div key={"drawer_list_" + i}>
                          <Link
                            activeClass="active"
                            to={name}
                            spy={true}
                            smooth={true}
                            offset={-65}
                            duration={500}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                this.toggleDrawer();
                              }}
                              className={classes.drawerListItem}
                            >
                              <ListItemIcon>{drawerIcons[name]}</ListItemIcon>
                              <ListItemText primary={content[language][name]} />
                            </ListItem>
                          </Link>
                          <Divider />
                        </div>
                      ))}
                    </List>
                  </Grid>
                  {mediaComponent && <Grid item>{mediaComponent}</Grid>}
                </Grid>
              </Drawer>
            </Grid>
            <Grid item>
              <List className={classes.list}>
                {list.map((name, i) => (
                  <Link
                    activeClass="active"
                    to={name}
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={500}
                    key={"menu_list_" + i}
                  >
                    <ListItem className={classes.listItem} bu>
                      <ListItemText primary={content[language][name]} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setLanguage("tr")}>
                <img className={classes.icon} src={trIcon} alt={"turkish"} />
              </IconButton>
              <IconButton onClick={() => setLanguage("en")}>
                <img className={classes.icon} src={ukIcon} alt={"english"} />
              </IconButton>
              <Link
                activeClass="active"
                to="stores"
                spy={true}
                smooth={true}
                offset={-65}
                duration={500}
              >
                <IconButton>
                  <RoomIcon className={classes.icon} />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  mediaComponent: PropTypes.object,
};

export default withStyles(styles)(MenuBar);
