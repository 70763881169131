const styles = theme => ({
  root: {
    textAlign: "center"
  },
  footer: {
    width: "100%"
  }
});

export default styles;
