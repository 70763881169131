const styles = theme => ({
  form: {
    textTransform: "capitalize"
  },
  line: {
    marginBottom: theme.spacing(2)
  },
  fieldsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  field: {
    width: "48%"
  }
});

export default styles;
