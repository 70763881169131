import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import cert1 from "./../../assets/images/about1.png";
import cert2 from "./../../assets/images/about2.png";

const content = {
  tr: {
    title: "hakkımızda",
    paragraph1:
      " Sayit Kaynak tarafından kurulan ve oto yedek parça ithalatındaki geçmişi 1960'lı yıllara dayanan firmamız, 2010' dan bu yana Kaynak Kardeşler Otomatik Şanzıman Parçaları Ticaret ve Sanayi Limited Şirketi adı altında faaliyetlerini sürdürmektedir.",
    paragraph2:
      " Pazarında öncü, 20 yıldır Türkiye'deki tek mümessili olduğumuz  A.B.D. menşeli Transtar Industries Inc. 'in  ürünleri ile beraber, yılların getirdiği güven ve tecrübeyi hizmetinize sunmanın gururunu yaşıyoruz.",
  },
  en: {
    title: "about us",

    paragraph1:
      "Kaynak Kardesler, along with being the sole official distributor of Transtar Industuries Inc. in Turkey for the last 20 years, has a history in dealing auto spare parts that goes as far back as 1960’s and was founded by Sayit Kaynak.",
    paragraph2:
      "With years of experience and the character we have established in the market, we are proud to be able to offer our products and services to our customers.",
  },
  certificates: [cert1, cert2],
};

const About = ({ classes, language }) => {
  const texts = content[language];
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h2" color="secondary">
          {texts.title}
        </Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.imgContainer}>
          <div
            className={classes.img}
            style={{ backgroundImage: `url(${content.certificates[0]})` }}
          ></div>

          <div
            className={classes.img}
            style={{ backgroundImage: `url(${content.certificates[1]})` }}
          ></div>
        </div>

        <div>
          <Typography className={classes.text} variant="body1" color="inherit">
            {texts.paragraph1}
          </Typography>
          <Typography className={classes.text} variant="body1" color="inherit">
            {texts.paragraph2}
          </Typography>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default withStyles(styles)(About);
