const styles = theme => ({
  iconContainer: {
    fontSize: theme.typography.fontSize * 2,
    margin: theme.typography.fontSize / 2
  },
  icon: {
    fontSize: theme.typography.fontSize * 2
  }
});

export default styles;
