import React, { Fragment } from "react";
import { Route, Switch } from "react-router";
import Home from "./../pages/Home";

export default () => (
  <Fragment>
    <Switch>
      <Route path="/*" component={Home} />
    </Switch>
  </Fragment>
);
