import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import CinzelBold from "./../assets/fonts/CinzelDecorative-Bold.woff2";

const cinzelBold = {
  fontFamily: "Cinzel-Bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Cinzel-Bold'),
    local('Cinzel-Bold-Regular'),
    url(${CinzelBold}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};

const headerStyle = {
  fontFamily: ["Times New Roman", "serif"].join(","),
  textTransform: "capitalize",
  marginTop: 18,
  marginBottom: 18,
  fontStyle: "italic"
};

const theme = responsiveFontSizes(
  createMuiTheme({
    container: {
      maxWidth: 1200,
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%"
    },
    typography: {
      fontFamily: ["Arial", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        ...headerStyle
      },
      h2: {
        ...headerStyle
      },
      h3: {
        ...headerStyle
      },
      h4: {
        ...headerStyle
      },
      h5: {
        ...headerStyle
      },
      h6: {
        ...headerStyle
      }
    },
    palette: {
      primary: {
        main: "#f6f6f6",
        contrastText: "#000",
        transparent: "rgba(68,68,68,0.58)"
      },
      secondary: {
        main: "#333333",
        contrastText: "#fff",
        transparent: "rgba(51, 51, 51,0.48)"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [cinzelBold]
        }
      }
    }
  })
);

const { breakpoints } = theme;

theme.container[breakpoints.down("sm")] = {
  paddingLeft: 8,
  paddingRight: 8
};

theme.paddingVertical = {
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2)
};

export default theme;
