import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  ListItemText,
  List,
  ListItem,
  Divider,
  Drawer,
  IconButton,
  Grid
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import Brands from "./../Brands";

import Swiper from "react-id-swiper";

import image0 from "./../../assets/images/products/1.png";
import image1 from "./../../assets/images/products/2.png";
import image2 from "./../../assets/images/products/3.png";
import image3 from "./../../assets/images/products/4.png";
import image4 from "./../../assets/images/products/5.png";
import image5 from "./../../assets/images/products/6.png";
import image6 from "./../../assets/images/products/7.png";
import image7 from "./../../assets/images/products/8.png";
import image8 from "./../../assets/images/products/9.png";
import image9 from "./../../assets/images/products/10.png";

const content = {
  en: {
    title: "products",
    catagories: [
      "automatic transmissions",
      "valve bodies",
      "solenoids",
      "transmission hard parts",
      "friction modules",
      "piston kits",
      "repair kits",
      "filters",
      "automatic transmission fluids",
      "direct-shift gearbox (DSG)"
    ]
  },
  tr: {
    title: "ürünlerimiz",
    catagories: [
      "otomatik şanzıman",
      "şanzıman beyni",
      "solenoid grupları",
      "şanzıman iç parçaları",
      "balata takımları",
      "piston takımları",
      "tamir takımları",
      "filtreler",
      "yağlar",
      "dsg"
    ]
  },
  images: [
    image0,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9
  ]
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      catagory: 0
    };
    this.swiper = null;
    this.swiperParams = {
      slidesPerView: 1,
      effect: "fade",
      loop: true,
      autoplay: {
        delay: 5000
      }
    };
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  setCatagory = catagory => {
    this.swiper.slideTo(catagory + 1);
    this.swiper.autoplay.start();
  };

  swiperRef = ev => {
    this.swiper = ev;
    ev.eventsListeners.slideChange[0] = () =>
      this.setState({ catagory: ev.realIndex });
  };

  render() {
    const { classes, language, mediaComponent } = this.props;
    const texts = content[language];

    const { catagory } = this.state;
    return (
      <div className={classes.root}>
        <Typography variant="h2" color="secondary">
          {texts.title}
        </Typography>
        <div className={classes.content}>
          <Swiper {...this.swiperParams} getSwiper={this.swiperRef}>
            {content.images.map((src, i) => (
              <div
                className={classes.imageContainer}
                key={"product_images_" + i}
              >
                <img
                  className={classes.image}
                  src={src}
                  alt={`catagory_${texts.catagories[i]}`}
                />
              </div>
            ))}
          </Swiper>
          <div className={classes.listContainer}>
            <List className={classes.list}>
              {texts.catagories.map((name, i) => (
                <div key={"poducts_list_" + i}>
                  <ListItem
                    button
                    component="a"
                    onClick={() => {
                      this.setCatagory(i);
                    }}
                  >
                    <ListItemText primary={name} />
                  </ListItem>
                  {i < texts.catagories.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </div>
          <div className={classes.menuContainer}>
            <div className={classes.menuText}>
              <Typography variant="body1" color="primary">
                {texts.catagories[catagory]}
              </Typography>
              <IconButton onClick={this.toggleDrawer} color="primary">
                <MoreVertIcon size="large" />
              </IconButton>
            </div>
            <Drawer
              open={this.state.open}
              onClose={this.toggleDrawer}
              anchor="right"
            >
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction="column"
                className={classes.drawer}
              >
                <Grid item>
                  <List className={classes.list}>
                    {texts.catagories.map((name, i) => (
                      <div key={"products_drawer_list_" + i}>
                        <ListItem
                          button
                          component="a"
                          onClick={() => {
                            this.setCatagory(i);
                            this.toggleDrawer();
                          }}
                        >
                          <ListItemText primary={name} />
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </Grid>
                {mediaComponent && <Grid item>{mediaComponent}</Grid>}
              </Grid>
            </Drawer>
          </div>
        </div>
        <Brands />
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  mediaComponent: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default withStyles(styles)(Products);
