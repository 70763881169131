import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";

const SocialMedia = ({ classes, phone, mobile }) => {
  return (
    <div>
      <div>
        <IconButton
          target="_blank"
          href="https://www.facebook.com/kaynakkardeslerotomatiksanziman"
          className={classes.iconContainer}
        >
          <FacebookIcon className={classes.icon} />
        </IconButton>
        <IconButton
          target="_blank"
          href={`https://api.whatsapp.com/send?phone=${mobile}`}
          className={classes.iconContainer}
        >
          <WhatsAppIcon className={classes.icon} />
        </IconButton>
        <IconButton
          className={classes.iconContainer}
          target="_blank"
          href={`tel:${phone}`}
        >
          <PhoneIcon className={classes.icon} />
        </IconButton>
      </div>
    </div>
  );
};

SocialMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  phone: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired
};

export default withStyles(styles)(SocialMedia);
