const width = window.innerWidth;
const styles = theme => ({
  root: {
    ...theme.contianer,
    ...theme.paddingVertical
  },

  content: {
    ...theme.container,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      textAlign: "left"
    }
  },
  imgContainer: {
    width: width,
    height: width,
    maxWidth: 350,
    maxHeight: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      marginRight: 80
    }
  },
  img: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "40%"
  },
  text: {
    marginBottom: theme.spacing(2)
  }
});

export default styles;
