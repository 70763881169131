const styles = (theme) => ({
  toolbar: {
    ...theme.container,
  },
  icon: {
    height: theme.typography.fontSize * 1.5,
    width: theme.typography.fontSize * 1.5,
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    display: "flex",
  },
  listItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "capitalize",
  },
  drawer: {
    minHeight: "90%",
  },

  drawerListItem: {
    textTransform: "capitalize",
    padding: theme.typography.fontSize,
    paddingLeft: theme.typography.fontSize * 2,
    minWidth: 200,
  },
  menu: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

export default styles;
