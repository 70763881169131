const w = window.innerWidth;
const styles = theme => ({
  root: { ...theme.paddingVertical },
  container: {
    height: w * 0.4,
    width: w * 0.8,
    [theme.breakpoints.up("md")]: {
      height: w / 12,
      width: w / 6
    },
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  }
});

export default styles;
